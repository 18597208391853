import { Typography } from "@mui/material";

const PageNotFound = () => {
  return (
    <div style={{width: '100%', textAlign: 'center'}}>
      <Typography variant="h1">404</Typography>
    </div>
  );
}
 
export default PageNotFound;