export const demoTabs = [
    {
        "owner": "",
        "numberOfStrings": 6,
        "name": "12 bar blues",
        "blocks": [
            {
                "label": "TUNING: EADGBE\n\nBluesy intro walk",
                "inputs": "|                          1 1 0  |\n|                          0 0 0  |\n|                          2 2    |\n|                          1 1    |\n| 7 7 5 5 4 4 3 3 2 0 1 2  2 2    |\n|                                 |",
                "dashes": "|-------------------------- - - --|\n|-------------------------- - - --|\n|-------------------------- - ----|\n|-------------------------- - ----|\n|- - - - - - - - - - - - -- - ----|\n|---------------------------------|",
                "cols": 35,
                "maxLength": 221,
                "_id": "6382930bff50a5d590725d89",
                "createdAt": "2022-11-26T22:28:27.447Z",
                "updatedAt": "2022-11-26T22:28:27.447Z"
            },
            {
                "label": " ",
                "inputs": "|                                                                              |\n|                                                                              |\n|                                                                              |\n|                     2 2 4 4  2 2 4 4                                         |\n| 2 2 4 4   2 2 4 4   0 0 0 0  0 0 0 0   2 2 4 4  2 2 4 4  2 2 4 4  2 2 4 4    |\n| 0 0 0 0   0 0 0 0                      0 0 0 0  0 0 0 0  0 0 0 0  0 0 0 0    |",
                "dashes": "|------------------------------------------------------------------------------|\n|------------------------------------------------------------------------------|\n|------------------------------------------------------------------------------|\n|--------------------- - - - -- - - - -----------------------------------------|\n|- - - - --- - - - --- - - - -- - - - --- - - - -- - - - -- - - - -- - - - ----|\n|- - - - --- - - - ---------------------- - - - -- - - - -- - - - -- - - - ----|",
                "cols": 80,
                "maxLength": 491,
                "_id": "6382930bff50a5d590725d8a",
                "createdAt": "2022-11-26T22:28:27.448Z",
                "updatedAt": "2022-11-26T22:28:27.448Z"
            },
            {
                "label": "  ",
                "inputs": "|                                                                              |\n|                                                                              |\n|                                                                              |\n| 2 2 4 4  2 2 4 4  2 2 4 4  2 2 4 4                                           |\n| 0 0 0 0  0 0 0 0  0 0 0 0  0 0 0 0  2 2 4 4  2 2 4 4  2 2 4 4   2 2 4 4      |\n|                                     0 0 0 0  0 0 0 0  0 0 0 0   0 0 0 0      |",
                "dashes": "|------------------------------------------------------------------------------|\n|------------------------------------------------------------------------------|\n|------------------------------------------------------------------------------|\n|- - - - -- - - - -- - - - -- - - - -------------------------------------------|\n|- - - - -- - - - -- - - - -- - - - -- - - - -- - - - -- - - - --- - - - ------|\n|------------------------------------- - - - -- - - - -- - - - --- - - - ------|",
                "cols": 80,
                "maxLength": 491,
                "_id": "6382930bff50a5d590725d8b",
                "createdAt": "2022-11-26T22:28:27.448Z",
                "updatedAt": "2022-11-26T22:28:27.448Z"
            },
            {
                "label": " ",
                "inputs": "|                                        |                                     |\n|                                        |   then repeat from intro walk       |\n|                                        |                                     |\n| 4 4 6 6  4 4 6 6  2 2 4 4  2 2 4 2     |                                     |\n| 2 2 2 2  2 2 2 2  0 0 0 0  0 0 0 0 2   |                                     |\n|                                    0 0 |                                     |",
                "dashes": "|---------------------------------------- -------------------------------------|\n|---------------------------------------- ---    -      -    -     -    -------|\n|---------------------------------------- -------------------------------------|\n|- - - - -- - - - -- - - - -- - - - ----- -------------------------------------|\n|- - - - -- - - - -- - - - -- - - - - --- -------------------------------------|\n|------------------------------------ - - -------------------------------------|",
                "cols": 80,
                "maxLength": 491,
                "_id": "6382930bff50a5d590725d8c",
                "createdAt": "2022-11-26T22:28:27.448Z",
                "updatedAt": "2022-11-26T22:28:27.448Z"
            }
        ],
        "tags": [
            "blues",
            "practice"
        ],
        "_id": "demo_blues_riff",
        "createdAt": "2022-11-26T22:28:27.448Z",
        "updatedAt": "2022-11-26T22:28:27.448Z",
        "__v": 0
    },
    {
      "_id": "riffin_sketchpad_tutorial",
      "owner": "c52209cb-6e0c-49f9-a4b0-cb5dbd25ae58",
      "numberOfStrings": 6,
      "name": "Riffin sketchpad tutorial",
      "blocks": [
          {
              "label": "Click the 'EDIT' button to open this tab in the sketchpad. The sketchpad is only available on large displays, if you're viewing this on mobile you will not see an 'EDIT' button.\n===\n",
              "inputs": "|    0            0          |\n|    0          0   0        |\n|    1        1       1      |\n|    2      2           2    |\n|    2    2               2  |\n|    0  0                   0|",
              "dashes": "|---- ------------ ----------|\n|---- ---------- --- --------|\n|---- -------- ------- ------|\n|---- ------ ----------- ----|\n|---- ---- --------------- --|\n|---- -- ------------------- |",
              "cols": 30,
              "maxLength": 191,
              "_id": "637c3c40c682ca4f60eb9341"
          },
          {
              "tempKey": "Wed Nov 23 2022 20:10:46 GMT-0500 (Eastern Standard Time)0.6697429678666145",
              "inputs": "|                                                |\n|                                                |\n|                                                |\n|                                                |\n|                                                |\n|                                                |",
              "dashes": "|------------------------------------------------|\n|------------------------------------------------|\n|------------------------------------------------|\n|------------------------------------------------|\n|------------------------------------------------|\n|------------------------------------------------|",
              "cols": 50,
              "maxLength": 311,
              "label": "Below is a staff for tablature. Give yourself some space to type - use the \"Staff Menu\" on the right to increase the length, then click anywhere on the staff to start writing! \n\nThe arrow keys are helpful for navigating."
          },
          {
              "label": "Above each staff is an area for notes. Take as many lines as you need.",
              "inputs": "|                                                15/17 17^ (bend all the way!) |\n|                                                                              |\n|   You can write notes on the staff too!                                      |\n|                                                                              |\n|                                                                              |\n|                                                                              |",
              "dashes": "|------------------------------------------------     -   -     -   -   -     -|\n|------------------------------------------------------------------------------|\n|---   -   -     -     -  -   -     -    --------------------------------------|\n|------------------------------------------------------------------------------|\n|------------------------------------------------------------------------------|\n|------------------------------------------------------------------------------|",
              "cols": 80,
              "maxLength": 491,
              "_id": "637c54d29e1c0a4310a8f3bc"
          },
          {
              "label": "Duplicate a chord behind the cursor by pressing \"]\". Try duplicating the D chord below!\nCheck out the 'Special inputs' panel for more cool tricks.\n",
              "inputs": "| 0| <--chord behind cursor:  0 0           D chord ->  2                      |\n| 0                           0 0                       4                      |\n| 1                           1 1                       2                      |\n| 2                           2 2                       0                      |\n| 2                           2 2                       x                      |\n| 0                           0 0                       x                      |",
              "dashes": "|-  -        -      -       -- - ----------- -     -  -- ----------------------|\n|- --------------------------- - ----------------------- ----------------------|\n|- --------------------------- - ----------------------- ----------------------|\n|- --------------------------- - ----------------------- ----------------------|\n|- --------------------------- - ----------------------- ----------------------|\n|- --------------------------- - ----------------------- ----------------------|",
              "cols": 80,
              "maxLength": 491,
              "_id": "637c54d29e1c0a4310a8f3bd"
          },
          {
              "label": "Use the 'add tag' input at the top of the screen to help organize your tablature.",
              "inputs": "|                                                |\n|                                                |\n<-- use the drag handle (=) to rearrange staffs! |\n|                                                |\n|                                                |\n|                                                |",
              "dashes": "|------------------------------------------------|\n|------------------------------------------------|\n   -   -   -    -      -   -  -         -       -|\n ------------------------------------------------|\n|------------------------------------------------|\n|------------------------------------------------|",
              "cols": 50,
              "maxLength": 311,
              "_id": "637c58127707340387e4d856"
          }
      ],
      "tags": [
          "practice"
      ]
  }
]